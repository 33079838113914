<template>
  <div style="background:#f0f2f5;">
    <!-- 顶部菜单栏 -->
    <div style="height:48px;background:#001529;line-height: 48px;position: fixed;width: 100vw;z-index: 2000">
      <div style="display:flex;justify-content: space-between;">
        <div style="display:flex;align-items: center;justify-content:center;width:208px;max-width: 208px;min-width: 208px;text-align: center;">
          <span style="color:#fff;text-align: center">VSTAR 健身后台管理</span>
        </div>
        <div style="display: flex;justify-content: flex-end;align-items: center;margin-right:32px;color:#FFFFFF;user-select: none;cursor:pointer;" @click="showUserSettingView()">
          <el-avatar shape="circle" size="small" :src="image_avatar"></el-avatar>
          <div style="margin-left: 12px">管理员</div>
        </div>
      </div>
    </div>
    <template v-if="show_user_setting">
      <div class="user-setting-view">
<!--        <div class="user-setting">-->
<!--          <span>&#187;</span>-->
<!--          <span>设置</span>-->
<!--        </div>-->
        <div class="user-setting" @click="onLogout">
          <span>&#187;</span>
          <span>登出</span>
        </div>
      </div>
    </template>
    <!-- 主页面 -->
    <div style="min-height: calc(100vh - 48px);">
      <router-view></router-view>
    </div>
  </div>
  <div style="width: 100%;height: 48px;line-height:48px;display: flex;justify-content: center;align-items: center;background:#f0f2f5;color: rgba(0,0,0,0.5);font-size: 12px">
    深圳市班班科技有限公司提供技术支持
  </div>
</template>

<script>
import {removeToken} from "@/utils/auth";

export default {
  name: "layout",
  data() {
    return {
      show_user_setting: false,
      image_avatar:'http://image.banbansx.com/user-avatar.png'
    }
  },
  methods:{
    showUserSettingView(){
      this.show_user_setting = true
      let _p = ()=>{
        if (!this.show_user_setting){
          return
        }
        document.removeEventListener('click',_p)
        this.hideUserSettingView()
      }
      document.addEventListener('click',_p,true)
    },
    hideUserSettingView(){
      this.show_user_setting = false
    },
    onLogout(){
      removeToken()
      location.reload()
    }
  }
}
</script>

<style scoped>
    .el-menu--horizontal>.el-menu-item{
        height:48px;
        line-height: 48px;
    }
    /deep/.el-menu--horizontal>.el-sub-menu .el-sub-menu__title{
        height:48px;
        line-height: 48px;
    }
    .user-setting{display: flex;justify-content: space-between;align-items: center;padding:0 24px;height: 40px;font-size: 14px;border-top: 1px solid #475669;cursor:pointer;}
    .user-setting-view{position: fixed;top:48px;right: 8px;width: 98px;color: #FFFFFF;background: #001529;border-radius: 0px 0px 8px 8px;cursor:pointer;}
</style>
