
// import VueRouter from 'vue-router'
import {createRouter,createWebHashHistory} from "vue-router";
// import Left from '@/components/nav/left'
// import Top from '@/components/nav/top'
import Layout from '@/views/layout'
import Body from '@/views/body'
// import Main from '@/views/main'


const _routes = [
    {
        path:'/',
        redirect:'/b/user-list'
    },
    {
        path:'/1',
        name:'1',
        component:Layout,
        children:[
            {
              name:'body',
              path:'body',
              component:Body,
                children:[
                    {
                        name:'main1',
                        path:'main1',
                        components: {
                            default:()=>import('@/views/main1')
                        }
                    },
                    {
                        name:'main2',
                        path:'main2',
                        components: {
                            default:()=>import('@/views/main2'),
                        }
                    },
                ]
            },
        ],
    },
    {
      path:'/2',
      name:'2',
      component:Layout,
      children:[
          {
              name:'body2',
              path:'body2',
              components: {
                  default:()=>import('@/views/body2'),
                  body:Body
              }
          }
      ]
    },
    {
        path:'/',
        component:Layout,
        children: [
            {
              path:'/b',
              component: Body,
              children: [
                  {
                      path:'user-list',
                      components:{
                          default:()=>import('@/views/b/user/user-list'),
                      }
                  },
                  {
                      path:'user-detail',
                      component: import('@/views/b/user/user-detail')
                  },
                  {
                      path:'fitness-list',
                      component:()=>import('@/views/b/pk/fitness-list')
                  },
                  {
                      path:'schedule-list',
                      component:()=>import('@/views/b/pk/schedule-list')
                  },
                  {
                      path:'order-list',
                      component:()=>import('@/views/b/order/order-list')
                  },
                  {
                      path:'setting-group-list',
                      component:()=>import('@/views/b/setting/setting-group-list')
                  },
                  {
                      path:'setting-group-item-list',
                      component:()=>import('@/views/b/setting/setting-group-item-list')
                  },
              ]
            },

        ],
    },
    {
        path:'/login',
        component:()=>import('@/views/login')
    },
]

export const router = createRouter({
    history:createWebHashHistory(),
    routes:_routes,
})

export default router


